:global(#app) {
  .add {
    backdrop-filter: blur(4px);
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.55);
    box-shadow:
      rgba(0, 0, 0, 0.1) 0 3px 6px,
      rgba(0, 0, 0, 0.1) 0 6px 12px;
    color: #fff;
    cursor: pointer;
    border: 1px solid black;
    // fill: #333;
    text-align: center;
    width: 100%;

    &:hover {
      box-shadow:
        0 16px 32px rgba(0, 0, 0, 0.1),
        0 32px 64px rgba(0, 0, 0, 0.1);
      transform: translate(0, -2px);
    }
  }

  .addGridIcon {
    display: block;
    margin: 0 auto 12px;
    width: 36px;
  }

  .addTitle {
    display: table-cell;
    font-size: 17px;
    line-height: 1;
    vertical-align: middle;
  }

  .addTitleWrapper {
    display: table;
    height: 100%;
    padding: 35px 21px;
    width: 100%;
  }

  .card {
    border-radius: 4px;
    height: 190px;
    transition: all 200ms ease;
  }

  .cardOverlay {
    background: #000000;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    position: absolute;
    right: 0;
    top: 0;
  }

  .cardsWrapper {
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    padding-top: 5%;
    background: url('../../assets/images/landingPage.svg') center / cover;
    height: 100%;
  }

  .heading {
    color: #fff;
    margin-left: 21%;
    font-size: 48px;

    @media only screen and (max-width: 1200px) {
      margin-left: 20px;
      font-size: 30px;
    }
  }

  .gridFix {
    flex: 1 0 auto;
    margin: 0;
    width: 60%;
    margin: auto;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .notification {
    background: #eb5a46;
    color: #fff;
    float: right;
    font-size: 12px;
    line-height: 20px;
    padding: 0px 6px;
    border: none;
    border-radius: 3px;
    outline: none;
    text-align: left;
    transition: background 0.3s ease;
  }

  .open {
    background: #555;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.1),
      0 6px 12px rgba(0, 0, 0, 0.1);
    color: #fff;
    position: relative;
    transition:
      transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
      box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow:
        0 16px 32px rgba(0, 0, 0, 0.1),
        0 32px 64px rgba(0, 0, 0, 0.1);
      transform: translate(0, -2px);
    }
  }

  .openTitle {
    bottom: 0;
    font-size: 24px;
    hyphens: auto;
    left: 0;
    line-height: 1.1;
    padding: 24px 20px;
    position: absolute;
    word-break: break-word;
  }

  .project {
    background: #22252a;
    flex: 1 1 auto;
    height: 100%;
    width: calc(100% - 200px);
  }

  .wrapper {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
  }

  .footerLogo {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}
