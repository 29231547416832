:global(#app) {
  .cover {
    background: url('../../assets/images/cover.svg') center / cover;
  }

  .descriptionSubtitle {
    font-size: 24px;
    font-weight: normal;
    margin-top: 8px;
  }

  .descriptionTitle {
    font-size: 52px;
    margin-bottom: 8px;
  }

  .descriptionWrapper {
    padding: 60px 0 0 80px;
    position: relative;
  }

  .descriptionWrapperOverlay {
    // background: rgba(33, 33, 33, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .footerImage {
    width: 120px;
    height: 70px;
    object-fit: contain;
  }

  .formTitle {
    font-size: 36px;
    font-weight: normal;
    margin-bottom: 32px;
  }

  .fullHeight {
    height: 100%;
  }

  .fullHeightPaddingFix {
    height: calc(100% + 1rem);
  }

  .inputLabel {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 4px;
    height: 55px;
  }

  .inputWrapper {
    width: 94%;
    margin: auto;
    margin-bottom: 16px;
  }

  .loginWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 440px;
    padding: 0 20px;
    justify-content: space-around;
  }

  .text {
    font-weight: bold;
    color: #231a6d;
    font-size: 32px;
    text-align: center;
  }

  .button:hover {
    background-color: #e55b00;
  }

  .button {
    margin-top: 55px;
    padding: 10px 0px;
    // height: 40px;
    background-color: #f2711c;
    border-radius: 7px;
  }

  .wrapper {
    background: #fff;
  }
}
